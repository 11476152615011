import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Breadcrumbs, Button, Grid, Link, SvgIcon, Typography, makeStyles } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { PlusCircle as PlusCircleIcon, MessageSquare as SmsIcon } from 'react-feather';
import SmsForm from './SmsForm';

const useStyles = makeStyles((theme) => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
}));

function Header({ className, customers, ...rest }) {
  const classes = useStyles();
  const [openApplication, setOpenApplication] = useState(false);

  const handleApplicationOpen = () => {
    setOpenApplication(true);
  };

  const handleApplicationClose = () => {
    setOpenApplication(false);
  };

  const smsCustomers = customers.filter((customer) => {
    const groups = customer.groups;
    const hasGroup = groups.length && groups[0];
    const resigned = groups.every((group) => group?.resigned_at);

    return hasGroup && !resigned;
  });

  return (
    <Grid className={clsx(classes.root, className)} container justify="space-between" spacing={3} {...rest}>
      <Grid item>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link variant="body1" color="inherit" to="/dashboard" component={RouterLink}>
            Dashboard
          </Link>
          <Typography variant="body1" color="textPrimary">
            Customers
          </Typography>
        </Breadcrumbs>
        <Typography variant="h3" color="textPrimary">
          All Customers
        </Typography>
        <Box mt={2}>
          {/* 
          <Button className={classes.action}>
            <SvgIcon fontSize="small" className={classes.actionIcon}>
              <UploadIcon />
            </SvgIcon>
            Import
          </Button>
          <Button className={classes.action}>
            <SvgIcon fontSize="small" className={classes.actionIcon}>
              <DownloadIcon />
            </SvgIcon>
            Export
          </Button>
          */}
        </Box>
      </Grid>
      <Grid item>
        <Button color="secondary" variant="contained" className={classes.action} onClick={handleApplicationOpen}>
          <SvgIcon fontSize="small" className={classes.actionIcon}>
            <SmsIcon />
          </SvgIcon>
          Send SMS
        </Button>
        <SmsForm
          customers={smsCustomers}
          onApply={handleApplicationClose}
          onClose={handleApplicationClose}
          open={openApplication}
        ></SmsForm>
        <Button
          color="secondary"
          variant="contained"
          className={classes.action}
          component={RouterLink}
          to="/customers/create"
        >
          <SvgIcon fontSize="small" className={classes.actionIcon}>
            <PlusCircleIcon />
          </SvgIcon>
          New Customer
        </Button>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
